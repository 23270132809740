import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchLegalForms = createAsyncThunk(
  'dicionaries/fetchLegalForms',
  async () => {
    const response = await fetch('api/register/legalForm')

    return await response.json()
  }
)

const legalFormsSlice = createSlice({
  name: 'legalForms',
  initialState: [],
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchLegalForms.fulfilled, (state, action) => {
      return action.payload
    })
  }
})

export default legalFormsSlice.reducer
