import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGet } from 'helpers/apiClient'

export const fetchApiKeyPermissions = createAsyncThunk(
  'dicionaries/apiKeyPermissions',
  async () => {
    const response = await apiGet('/api/apikey/getPermissionList', true)
    if (!response) return []

    return await response.json()
  }
)

const apiKeyPermissionsSlice = createSlice({
  name: 'apiKeyPermissions',
  initialState: [],
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchApiKeyPermissions.fulfilled, (state, action) => {
      return action.payload
    })
  }
})

export default apiKeyPermissionsSlice.reducer
