import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const PasswordResetRequest = React.lazy(() =>
  import('./views/pages/passwordResetRequest/PasswordResetRequest')
)
const PasswordReset = React.lazy(() =>
  import('./views/pages/passwordReset/PasswordReset')
)

const Register = React.lazy(() => import('./views/pages/register/Register'))
const RegisterConfirmation = React.lazy(() =>
  import('./views/pages/registerConfirmation/RegisterConfirmation')
)

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path='/login' name='Logowanie' element={<Login />} />
            <Route
              exact
              path='/password/reset-request'
              name='Reset hasła'
              element={<PasswordResetRequest />}
            />
            <Route
              exact
              path='/password/reset/:requestId'
              name='Reset hasła'
              element={<PasswordReset />}
            />
            <Route
              exact
              path='/register'
              name='Rejestracja'
              element={<Register />}
            />
            <Route
              exact
              path='/confirmation/:requestId'
              name='Potwierdzenie rejestracji'
              element={<RegisterConfirmation />}
            />
            <Route path='*' name='Home' element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
