import { configureStore } from '@reduxjs/toolkit'
import apiKeyPermissionsReducer from './reducers/apiKeyPermissionsReducer'
import groupsReducer from './reducers/groupsReducer'
import legalFormsReducer from './reducers/legalFormReducer'
import loginModalReducer from './reducers/loginModalReducers'
import sidebarReducer from './reducers/sidebarReducer'
import toasterReducer from './reducers/toasterReducer'
import userDictsReducer from './reducers/userDicts'
import userReducer from './reducers/userReducer'

const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    user: userReducer,
    legalForms: legalFormsReducer,
    apiKeyPermissions: apiKeyPermissionsReducer,
    loginModal: loginModalReducer,
    toaster: toasterReducer,
    groups: groupsReducer,
    userDicts: userDictsReducer
  }
})

export default store
