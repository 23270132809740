import { createSlice } from '@reduxjs/toolkit'

const toasterReducer = createSlice({
  name: 'toaster',
  initialState: {},
  reducers: {
    pushToast(state, action) {
      return { ...action.payload }
    }
  }
})

export const { pushToast } = toasterReducer.actions
export default toasterReducer.reducer
