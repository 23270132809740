import { createSlice } from '@reduxjs/toolkit'

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: { show: true, unfoldable: false },
  reducers: {
    showToggle(state, action) {
      state.show = action.payload
    },
    unfoldableToggle(state, action) {
      state.unfoldable = action.payload
    }
  }
})

export const { showToggle, unfoldableToggle } = sidebarSlice.actions
export default sidebarSlice.reducer
