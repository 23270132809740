import { loginModalToggle } from 'state/reducers/loginModalReducers'
import { pushToast } from 'state/reducers/toasterReducer'
import store from '../state/store'

export const apiGet = async (url, redirectToLogin = false) => {
  const response = await fetch(url)
  if (response.status === 401) {
    if (redirectToLogin)
      window.location.href = `/login?redirectUrl=${window.location.pathname}`
    else {
      store.dispatch(loginModalToggle(true))
      store.dispatch(
        pushToast({
          body: 'Użytkownik niezalogowany',
          color: 'danger'
        })
      )
      return null
    }
  }

  return response
}

export const apiPost = async (url, body, redirectToLogin = false) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

  if (response.status === 401) {
    if (redirectToLogin) {
      window.location.href = `/login?redirectUrl=${window.location.pathname}`
    } else {
      store.dispatch(loginModalToggle(true))
      store.dispatch(
        pushToast({
          body: 'Użytkownik niezalogowany',
          color: 'danger'
        })
      )
      return null
    }
  }

  return response
}
