import { createSlice } from '@reduxjs/toolkit'

const loginModalReducer = createSlice({
  name: 'loginModal',
  initialState: false,
  reducers: {
    loginModalToggle(state, action) {
      return action.payload
    }
  }
})

export const { loginModalToggle } = loginModalReducer.actions
export default loginModalReducer.reducer
