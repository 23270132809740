import { createSlice } from '@reduxjs/toolkit'

const userDicts = createSlice({
  name: 'userDicts',
  initialState: { userStatuses: null, userRoles: null },
  reducers: {
    userStatusesSet(state, action) {
      return { ...state, userStatuses: [...action.payload] }
    },
    userRolesSet(state, action) {
      return { ...state, userRoles: [...action.payload] }
    }
  }
})

export const { userStatusesSet, userRolesSet } = userDicts.actions
export default userDicts.reducer
