import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchGroups = createAsyncThunk('transaction/groups', async () => {
  const response = await fetch('api/transaction/groups')

  return await response.json()
})

const groupsSlice = createSlice({
  name: 'groups',
  initialState: [],
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      return action.payload
    })
  }
})

export default groupsSlice.reducer
